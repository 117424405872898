import axios from 'redaxios';
import { WeatherStationActual } from 'types/observations';

const getObservationsByWeatherStationId = async (
	weatherstationid?: number
): Promise<WeatherStationActual> => {
	if (!weatherstationid) {
		return Promise.reject(new Error('Invalid/ missing "weatherstationid"'));
	}
	const res = await axios.get(
		`https://observations.buienradar.nl/1.0/actual/weatherstation/${weatherstationid}`
	);
	return res.data;
};

export default getObservationsByWeatherStationId;
