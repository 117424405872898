import { useQuery, useQueryClient } from 'react-query';
import { WeatherForecast, WeatherForecastBaseData } from 'types/forecastAPI';
import { WeatherStationActual } from 'types/observations';
import { GeoLocation } from 'types/locationAPI';
import {
	defaultLocations,
	useLocationState,
} from 'utils/contexts/LocationsContext';
import getForecastDataByLocationId from 'utils/getForecast';
import { getLocationById } from 'utils/getLocation';
import getObservationsByWeatherStationId from 'utils/getObservations';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';
import { getAltTextWeatherIcon } from 'utils/getAltTextHelper';

import cssVariables from 'styles/variables.module.scss';
import styles from './CurrentWeatherMobile.module.scss';
import useAppLocation from 'utils/hooks/useAppLocation';

export function CurrentWeatherMobileWithData() {
	const isInWindow = useIsWindowSize({
		mediaQuery: cssVariables.desktopSMax,
		initialState: true,
	});
	const { data, isLoading, isError } = useGetCurrentWeather({ isInWindow });

	return (
		<CurrentWeatherMobile
			isInWindow={isInWindow}
			data={data}
			isLoading={isLoading}
			isError={isError}
		/>
	);
}

export default function CurrentWeatherMobile({
	data,
	isLoading,
	isError,
	isInWindow,
}: {
	data: WeatherStationActual | TFilteredForecastData;
	isLoading: boolean;
	isError: boolean;
	isInWindow: boolean;
}) {
	if (!isInWindow) return null;

	if (!data || isLoading || isError) {
		if (isError) console.error(isError); // TODO: should report to an APM
		return (
			<div className={styles.container}>
				<div className={styles.block}> °C</div>
				<div className={`${styles.block} ${styles.borders}`}> mm</div>
				<div className={styles.block}></div>
			</div>
		);
	}
	const {
		iconcode,
		temperature,
		precipitationmm = 0, //TODO: it seems that if there is no expected rain, the precipitationmm prop will be omitted from the data
		winddirection,
		windspeedBft,
	} = data;

	return (
		<div className={styles.container}>
			<div className={styles.block}>
				<img
					className={styles.image}
					src={`https://cdn.buienradar.nl/resources/images/icons/weather/116x116/${iconcode}.png`}
					alt={iconcode ? getAltTextWeatherIcon(iconcode) : ''}
					width="30"
					height="30"
				/>{' '}
				{temperature
					? `${temperature.toString().replace('.', ',')} °C`
					: null}
			</div>
			<div className={`${styles.block} ${styles.borders}`}>
				{precipitationmm !== null
					? `${precipitationmm.toString().replace('.', ',')} mm`
					: null}
			</div>
			<div className={styles.block}>
				<img
					className={styles.image}
					src={`https://cdn.buienradar.nl/resources/images/icons/wind/${winddirection}.png`}
					alt="windrichting"
					width="20"
					height="20"
				/>
				{winddirection}
				{windspeedBft}
			</div>
		</div>
	);
}

/* useGetWeatherStationId checks if the 'currentLocation' has a weatherstationid (and if it's a city in The Netherlands).
 * It a weatherstationid is present it will return that, otherwise it will fetch it based on the currentlocation
 */
function useGetWeatherStationId({
	location,
	isNL,
	isInWindow,
}: {
	location: GeoLocation;
	isNL: boolean;
	isInWindow: boolean;
}) {
	const weatherStationId = location?.weatherstationid;
	const { data } = useQuery(
		['locationByIdData', location.id],
		() => getLocationById({ id: location.id }),
		{
			retry: false,
			enabled: !weatherStationId && isNL && isInWindow,
		}
	);
	return weatherStationId ? weatherStationId : data?.weatherstationid;
}

/* useGetCurrentWeather returns some weather data based on the currentLocation.
 * If the currentLocation is based within The Netherlands we can fetch the data from the Observations API (getObservationsByWeatherStationId).
 * If the currentLocation is NOT within The Netherlands we need to fetch data from the Forecast API. We need to filter and sanitize the data to return similar properties to match the Observations API.
 */
function useGetCurrentWeather({ isInWindow }: { isInWindow: boolean }): {
	data: WeatherStationActual | TFilteredForecastData;
	isLoading: boolean;
	isError: boolean;
} {
	const appLocation = useAppLocation();
	const defaultLocation = defaultLocations[appLocation];
	const { weatherstationid: dfltWeatherStationId } = defaultLocation;
	const queryClient = useQueryClient();
	const { currentLocation } = useLocationState();
	const isNL = currentLocation.countrycode === 'NL';
	const weatherStationId = useGetWeatherStationId({
		location: currentLocation,
		isNL,
		isInWindow,
	});

	const {
		data: weatherStationData,
		isLoading: weatherStationisLoading,
		isError: weatherStationIsError,
	} = useQuery(
		['weatherStationData', weatherStationId],
		() => getObservationsByWeatherStationId(weatherStationId),
		{
			retry: false,
			enabled: isNL && weatherStationId !== undefined && isInWindow,
			initialData: queryClient.getQueryData([
				'weatherStationData',
				dfltWeatherStationId,
			]),
			keepPreviousData: true,
		}
	);

	const {
		data: forecastData,
		isLoading: forecastIsLoading,
		isError: forecastIsError,
	} = useQuery(
		['forecastData', currentLocation.id],
		() => getForecastDataByLocationId(currentLocation.id),
		{
			retry: false,
			enabled: !isNL && isInWindow,
			keepPreviousData: true,
		}
	);

	if (isNL) {
		return {
			data: weatherStationData,
			isLoading: weatherStationisLoading,
			isError: weatherStationIsError,
		};
	} else {
		return {
			data: getFilteredForecastData(forecastData),
			isLoading: forecastIsLoading,
			isError: forecastIsError,
		};
	}
}

interface FilteredForecastData extends WeatherForecastBaseData {
	windspeedBft: number;
}

type TFilteredForecastData = FilteredForecastData | void;

function getFilteredForecastData(
	data: WeatherForecast | undefined
): TFilteredForecastData {
	if (
		!data ||
		!data.days ||
		data.days.length === 0 ||
		!data.days[0].hours ||
		data.days[0].hours.length < 1
	) {
		return;
	}
	const forecastData = data.days[0].hours[0] as FilteredForecastData;
	if (!forecastData || !forecastData?.beaufort) return;
	forecastData.windspeedBft = forecastData.beaufort;

	return forecastData;
}
