export function getAltTextWeatherIcon(weatherIconName: string): string {
	const uppercaseWeatherIconName = weatherIconName.toUpperCase();
	switch (uppercaseWeatherIconName) {
		case 'A':
			return 'Droog en zonnig weer.';
		case 'AA':
			return 'Droog en onbewolkt weer.';
		case 'B':
		case 'O':
			return 'Mix van zon en wolken.';
		case 'BB':
		case 'OO':
			return 'Mix van opklaringen en wolken.';
		case 'C':
		case 'CC':
			return 'Zwaar bewolkt.';
		case 'D':
			return 'Afwisseling van zon en wolken, lokaal kans op mist.';
		case 'DD':
			return 'Afwisseling van opklaringen en wolken, lokaal kans op mist.';
		case 'F':
		case 'K':
			return 'Zon en een enkele bui.';
		case 'FF':
		case 'KK':
			return 'Opklaringen met een enkele bui.';
		case 'G':
			return 'Kans op enkele onweersbuien, tussendoor ruimte voor de zon.';
		case 'GG':
			return 'Kans op enkele onweersbuien, tussendoor wat opklaringen.';
		case 'H':
			return 'Een afwisseling van zon en buien.';
		case 'HH':
			return 'Een afwisseling van opklaringen en buien.';
		case 'I':
			return 'Zonnige perioden afgewisseld met sneeuwbuien.';
		case 'II':
			return 'Opklaringen afgewisseld met sneeuwbuien.';
		case 'J':
			return 'Veel ruimte voor de zon met een enkele wolk.';
		case 'JJ':
			return 'Veel ruimte voor opklaringen met een enkele wolk.';
		case 'M':
		case 'MM':
			return 'Zwaar bewolkt met wat lichte regen.';
		case 'N':
			return 'Zon en lokaal nevel of mist.';
		case 'NN':
			return 'Opklaringen en lokaal nevel of mist.';
		case 'L':
		case 'LL':
		case 'Q':
		case 'QQ':
			return 'Zwaar bewolkt en buien.';
		case 'R':
			return 'Zwaar bewolkt met weinig ruimte voor de zon.';
		case 'RR':
			return 'Zwaar bewolkt met weinig ruimte voor opklaringen.';
		case 'S':
		case 'SS':
			return 'Bewolkt en kans op onweersbuien.';
		case 'T':
		case 'TT':
			return 'Zwaar bewolkt en sneeuwbuien.';
		case 'U':
			return 'Zon en een enkele sneeuwbui.';
		case 'UU':
			return 'Opklaringen en een enkele sneeuwbui.';
		case 'V':
		case 'VV':
			return 'Zwaar bewolkt met lichte sneeuwval.';
		case 'W':
		case 'WW':
			return 'Zwaar bewolkt en winterse neerslag mogelijk.';
		default:
			return '-';
	}
}
